import React, { PureComponent } from "react";
import axios from "axios";
import { withFormik } from "formik";
import Cards from "react-credit-cards";
import InputMask from "react-text-mask";
import "react-credit-cards/es/styles-compiled.css";
import warning from "../img/warning.png";
import "../index.css";

const unMaskDigits = (value = "") => value.replace(/[^\d]/g, "");

function translateErrorMessage(code_status) {
  switch (code_status) {
    case "1":
      return "Expiração ano: parâmetro possui tamanho inválido";
    case "2":
      return "Expiração ano: parâmetro possui formato inválido";
    case "3":
      return "Expiração ano: parâmetro requerido não informado";
    case "4":
      return "Cavv: parâmetro possui tamanho inválido";
    case "5":
      return "Cavv: parâmetro possui formato inválido";
    case "6":
      return "Código Postal: parâmetro possui tamanho inválido";
    case "7":
      return "Código Postal: parâmetro possui formato inválido";
    case "8":
      return "Código Postal: parâmetro requerido não informado";
    case "9":
      return "Complemento: parâmetro possui tamanho inválido";
    case "10":
      return "Complemento: parâmetro possui formato inválido";
    case "11":
      return "Taxa de Embarque: parâmetro possui formato inválido";
    case "12":
      return "Número do Documento: parâmetro possui tamanho inválido";
    case "13":
      return "Número do Documento: parâmetro possui formato inválido";
    case "14":
      return "Número do Documento: parâmetro requerido não informado";
    case "15":
      return "Código de Segurança: parâmetro possui tamanho inválido";
    case "16":
      return "Código de Segurança: parâmetro possui formato inválido";
    case "17":
      return "Distribuição afiliador: parâmetro possui tamanho inválido";
    case "18":
      return "Distribuição afiliador: parâmetro possui formato inválido";
    case "19":
      return "XID: parâmetro possui tamanho inválido";
    case "20":
      return "ECI: parâmetro possui formato inválido";
    case "21":
      return "XID: parâmetro requerido por cartão VISA não encontrado";
    case "22":
      return "Rua: parâmetro requerido não informado";
    case "23":
      return "Rua: parâmetro possui formato inválido";
    case "24":
      return "Afiliação: parâmetro possui tamanho inválido";
    case "25":
      return "Afiliação: parâmetro possui formato inválido";
    case "26":
      return "Afiliação: parâmetro requerido não informado";
    case "27":
      return "Parâmetro Cavv ou ECI não informado";
    case "33":
      return "Expiração mês: parâmetro possui formato inválido";
    case "35":
      return "Expiração mês: parâmetro requerido não informado";
    case "36":
      return "Número do cartão: parâmetro possui tamanho inválido";
    case "37":
      return "Número do cartão: parâmetro possui formato inválido";
    case "38":
      return "Número do cartão: parâmetro requerido não informado";
    case "40":
      return "Referência: formato de parâmetro inválido";
    case "42":
      return "Referência: o número do pedido já existe";
    case "51":
      return "Produto ou serviço desativado para este comerciante, Entre em contato com a Rede";
    case "53":
      return "Transação não permitida para o emissor, Entre em contato com a Rede";
    case "55":
      return "Nome do titular do cartão: parâmetro possui tamanho inválido";
    case "56":
      return "Erro nos dados informados, Tente novamente";
    case "57":
      return "Afiliação: comerciante inválido";
    case "58":
      return "Não autorizado, Entre em contato com o emissor";
    case "64":
      return "Transação não processada, tente novamente";
    case "69":
      return "Transação não permitida para esse produto ou serviço";
    case "73":
      return "Valor: parâmetro requerido não informado";
    case "74":
      return "Falha na comunicação";
    case "80":
      return "Não autorizado, fundos insuficientes";
    case "86":
      return "Cartão expirado";
    case "89":
      return "Token: token inválido";
    case "101":
      return "Não autorizado, problemas no cartão, entre em contato com o emissor";
    case "103":
      return "Não autorizado, Por favor, tente novamente";
    case "105":
      return "Não autorizado, cartão restrito";
    case "106":
      return "Erro no processamento do emissor, Por favor, tente novamente";
    case "107":
      return "Não autorizado, Por favor, tente novamente";
    case "108":
      return "Não autorizado, Valor não permitido para este tipo de cartão";
    case "109":
      return "Não autorizado, Cartão inexistente";
    case "110":
      return "Não autorizado, Tipo de transação não permitido para este cartão";
    case "111":
      return "Não autorizado, Fundos insuficientes";
    case "112":
      return "Não autorizado, A data de validade expirou";
    case "113":
      return "Não autorizado, Risco moderado identificado pelo emissor";
    case "115":
      return "Não autorizado, Excedeu o limite de transações permitido no período";
    case "116":
      return "Não autorizado, Entre em contato com o emissor do cartão";
    case "118":
      return "Não autorizado, Cartão bloqueado";
    case "119":
      return "Não autorizado, Código de segurança inválido";
    case "151":
      return "Parcelas maior que o permitido";
    case "359":
      return "Reembolso bem-sucedido";
    case "370":
      return "Falha na solicitação. Entre em contato com a Rede";
    case "899":
      return "Não obteve sucesso. contate a rede";
    case "3025":
      return "Negar Categoria 01: Este cartão não deve ser usado";
    default:
      return "Erro desconhecido, tente novamente!";
  }
}

class Product extends PureComponent {
  state = {
    accepted: false,
    showContract: false,
    onCVC: false,
    success: false,
    issuer: "",
  };

  componentDidMount() {
    if (!this.props.contract || !this.props.contract.body) {
      this.setState({
        accepted: true,
      });
    }
  }

  creditCardMask = (value) => {
    const { maxLength } = this.state;

    const newValue = unMaskDigits(value);

    if (maxLength === 14) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    if (maxLength === 15) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    if (maxLength === 19 && newValue.length > 18) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    if (maxLength === 19 && newValue.length > 17) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    if (maxLength === 19 && newValue.length > 16) {
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }

    return [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  };

  onChange = ({ target }) => this.props.setFieldValue(target.name, target.value.toUpperCase());

  changeAcceptance = () =>
    this.setState({
      accepted: !this.state.accepted,
    });

  toggleContract = () =>
    this.setState({
      showContract: !this.state.showContract,
    });

  openContract = () => {
    localStorage.setItem("ibc-contract", this.props.contract.body);

    window.open("pdf", "_blank");
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
      error: false,
    });

    const { issuer } = this.state;
    const { values, token } = this.props;

    const formData = new FormData();
    formData.append("card_number", unMaskDigits(values.number));
    formData.append("card_holder_name", values.name);
    formData.append("card_cvv", values.cvc);
    formData.append("card_expiration_month", values.expiry.slice(0, 2));
    formData.append("card_expiration_year", values.expiry.slice(5, 7));
    formData.append("flag", issuer);

    try {
      const { data } = await axios({
        url: `https://api.grupoacelerador.com.br/payment_orders/${token}/checkout`,
        data: formData,
        method: "post",
        processData: false,
        contentType: false,
      });

      if (!data || data.returnCode !== "00") {
        const errorMessage = translateErrorMessage(data.returnCode);
        throw new Error(errorMessage);
      }

      this.setState({
        success: true,
      });
    } catch (error) {
      if (error?.response?.data?.returnMessage) {
        return this.setState({
          loading: false,
          error: error.response.data.returnMessage,
        });
      }

      if (error?.message) {
        return this.setState({
          loading: false,
          error: error.message,
        });
      }
    }

    this.setState({ loading: false });
  };

  render() {
    const { handleChange, values, contract } = this.props;
    const { accepted, error, loading, numberValid, onCVC, showContract, success } = this.state;

    return (
      <div className="contract">
        {contract?.body && (
          <div>
            <h3>Contrato</h3>
            <div className="">
              <label>
                <input onChange={this.changeAcceptance} type="checkbox" /> Declaro que aceito o{" "}
                <span onClick={this.toggleContract}>
                  contrato de prestação de serviços educacionais.
                </span>
              </label>
            </div>
            <div id="contract" className={showContract ? "visible" : ""}>
              <div className="wrapper">
                <div className="buttons">
                  <p onClick={this.openContract}>Salvar em PDF</p>
                  <p onClick={this.toggleContract}>Fechar</p>
                </div>
                <div className="content">{contract.body}</div>
              </div>
            </div>
          </div>
        )}

        <h3>Seus Dados</h3>
        <form onSubmit={this.handleSubmit} id="credit-card-info">
          <div className="qtd-buttons">
            <span className="active">Cartão de crédito</span>
          </div>

          <div className="credit-card">
            <div>
              <Cards
                callback={({ issuer, maxLength }, valid) =>
                  this.setState({
                    issuer,
                    maxLength,
                    numberValid: valid,
                  })
                }
                number={values.number}
                name={values.name}
                expiry={values.expiry}
                cvc={values.cvc}
                locale={{ valid: "Data Venc." }}
                placeholders={{
                  name: "SEU NOME AQUI",
                }}
                focused={onCVC ? "cvc" : ""}
              />
            </div>
            <div className="form">
              <div className="form-control">
                <label htmlFor="number">Número</label>
                <InputMask
                  className={values.number && !numberValid ? "error" : ""}
                  onChange={handleChange}
                  value={values.number}
                  name="number"
                  id="number"
                  mask={this.creditCardMask}
                  required
                  disabled={success || !accepted}
                />
              </div>
              <div className="form-control">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  onChange={this.onChange}
                  value={values.name}
                  name="name"
                  id="name"
                  required
                  disabled={success || !accepted}
                />
              </div>
              <div className="group-form">
                <div className="form-control">
                  <label htmlFor="expiry">Vencimento</label>
                  <InputMask
                    type="text"
                    onChange={handleChange}
                    value={values.expiry}
                    name="expiry"
                    id="expiry"
                    placeholder="01/2020"
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    required
                    disabled={success || !accepted}
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="cvc">Código de Segurança</label>
                  <input
                    type="number"
                    onFocus={() => this.setState({ onCVC: true })}
                    onBlur={() => this.setState({ onCVC: false })}
                    onChange={handleChange}
                    value={values.cvc}
                    name="cvc"
                    id="cvc"
                    placeholder="000"
                    max={this.state.issuer === "amex" ? "9999" : "999"}
                    required
                    disabled={success || !accepted}
                  />
                </div>
              </div>
              <div className="submit-payment">
                {success && <p className="success-msg">Pagamento efetuato com sucesso!</p>}
                {error && <p className="error-msg">{error}</p>}
                {!success && (
                  <button disabled={loading || !accepted} className="btn-submit">
                    {loading ? "ENVIANDO" : "PAGAR"}
                  </button>
                )}
              </div>
            </div>
          </div>

          <section className="payment-info">
            <img src={warning} alt="alert info" />
            <span>
              O Grupo Acelerador não armazena nem registra os dados de seu cartão de
              crédito. As informações serão inseridas diretamente no aplicativo da Cielo sem nenhuma
              intermediação.
            </span>
          </section>
        </form>
      </div>
    );
  }
}

const mapPropsToValues = () => ({
  name: "",
  number: "",
  expiry: "",
  cvc: "",
  value: "",
});

export default withFormik({ mapPropsToValues })(Product);
