import React from "react";
import { makeStyles } from "@mui/styles";
import cx from "classnames";

const Client = ({ order }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <h2 className={classes.title}>Dados do Cliente</h2>
        <p>
          <strong>Nome: </strong> {order && order.customer && order.customer.name}
        </p>

        <p>
          <strong>E-mail: </strong>
          {order && order.customer
            ? order.customer.email && order.customer.email.length && order.customer.email.join
              ? order.customer.email.join(", ")
              : order.customer.email
            : ""}
        </p>

        <p>
          <strong>CPF: </strong> {order && order.customer && order.customer.document}
        </p>
      </div>

      <div className={cx(classes.box)}>
        <h2 className={classes.title}>Consultor</h2>
        <p>
          <strong>Nome: </strong> {order && order.seller && order.seller.name}
        </p>

        <p>
          <strong>E-mail: </strong>
          {order && order.seller ? order.seller.email : ""}
        </p>
      </div>

      <div className={classes.box}>
        <h2 className={classes.title}>Pedido</h2>
        <h5> {order.order_id ? `#${order.order_id}` : "Pedido inválido"}</h5>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 20,
  },
  box: {
    flex: 1,
    flexShrink: 0,
    maxWidth: 220,
  },
  title: {
    textTransform: "uppercase",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    color: "#000 !important",
  },
});

export default Client;
