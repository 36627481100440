import React from 'react';
import logo from '../img/logo.png'
import payment from '../img/payments.png'

import '../index.css';

const Menu = () => (
  <div className="main-menu">
    <header className="content">
      <h1 className="logo">
        <img src={logo} alt="Grupo Acelerador"/>
      </h1>
      <div className="payments">
        <img src={payment} alt="Payments"/>
      </div>
    </header>
  </div>
)

export default Menu