import React, { PureComponent } from "react";
import axios from "axios";

import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Client from "../components/Client";
import Product from "../components/Product";
import CreditCard from "../components/CreditCard";
import Order from "../components/Order";
import "../index.css";

class Payment extends PureComponent {
  state = {
    loading: true,
    order: {},
    token: "",
  };

  getData = async (token) => {
    try {
      const { data } = await axios.get(`https://api.grupoacelerador.com.br/${token}`);

      if (!data || !data.order_id) {
        throw new Error();
      }

      this.setState({
        token,
        order: data,
      });
    } catch (error) {
      console.error("getData", error);
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.token &&
      this.props.match.params.token !== "unauthorize"
    ) {
      return this.getData(this.props.match.params.token);
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { order, loading, token } = this.state;

    return (
      <section id="payment">
        <Menu />
        {loading && (
          <div className="loading">
            <h5>Carregando...</h5>
          </div>
        )}
        {!loading && !order.order_id && (
          <div className="loading error">
            <h5>Ocorreu um erro com seu link, tente novamente mais tarde.</h5>
            <h6>Caso o erro permaneca contate o suporte.</h6>
          </div>
        )}
        {!loading && order.order_id && (
          <main>
            {/* <h2>Sistema de pagamento</h2> */}
            <Client order={order} />
            <Order products={order.products} />
            <Product installments={order.installments} />
            {order.status === "paid" && (
              <section className="paid-content">
                <h6>Pagamento já realizado</h6>
              </section>
            )}
            {order.status === "open" && (
              <CreditCard contract={order.contract} value={order.amount} token={token} />
            )}
          </main>
        )}
        <Footer />
      </section>
    );
  }
}

export default Payment;
