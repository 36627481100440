import React from "react";
import payment from "../img/payments.png";
import credit_card from "../img/credit-cards.png";
import logo from "../img/logo-footer.png";

const Footer = () => (
  <div>
    <footer>
      <section className="payments">
        <div className="security">
          <h4>Segurança</h4>
          <img src={payment} alt="Security" />
        </div>
        <div className="credit-cards">
          <h4>Formas de Pagamento</h4>
          <img src={credit_card} alt="Credit Cards" />
        </div>
        <div className="logo">
          <img src={logo} alt="Grupo Acelerador" />
        </div>
      </section>
    </footer>
  </div>
);

export default Footer;
