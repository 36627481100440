import React from "react";
import { conformToMask } from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { makeStyles } from "@mui/styles";
import cx from "classnames";

import "../index.css";
import { useTableStyles } from "./table-style";

const moneyMask = createNumberMask({
  prefix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
});

const getMaskedValue = (value) => conformToMask(`${value}`, moneyMask(`${value}`)).conformedValue;

const getValue = (value) =>
  `${getMaskedValue(value.slice(0, value.length - 2))}, ${value.slice(value.length - 2)}`;

const Order = ({ products = [] }) => {
  const classes = useTableStyles();

  return (
    <div>
      <h3 className={classes.title}>Dados do Pedido</h3>

      <div className={classes.table}>
        <div className={classes.header}>
          <h3 className={classes.header_text} style={{ width: "60%" }}>
            Produto
          </h3>
          <h3 className={cx(classes.header_text, classes.header_border)} style={{ width: "20%" }}>
            Quantidade
          </h3>
          <h3 className={classes.header_text} style={{ width: "20%" }}>
            Preço
          </h3>
        </div>
        {products.map((product, index) => (
          <div
            key={index}
            className={cx(classes.row, {
              [classes.row_inverted]: index % 2,
            })}
          >
            <h3
              className={cx(classes.row_text, classes.row_border_bottom)}
              style={{ width: "60%" }}
            >
              {product.name}
            </h3>
            <h3
              className={cx(classes.row_text, classes.row_border_bottom, classes.row_border)}
              style={{ width: "20%" }}
            >
              {product.quantity}
            </h3>
            <h3
              className={cx(classes.row_text, classes.row_border_bottom)}
              style={{ width: "20%" }}
            >{`R$ ${getValue(product.price)}`}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
