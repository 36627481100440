import React, { PureComponent } from 'react';

import '../styles.css';

export default class pdf extends PureComponent {
  state = {
    contract: ''
  }

  componentDidMount() {
    const contract = localStorage.getItem('ibc-contract');

    if (!contract) {
      return window.location = 'unauthorized';
    }

    this.setState({
      contract
    })

    window.print()
  }

  render() {
    return (
      <div className="content">
        {this.state.contract}
      </div>
    );
  }
}
