import React, { PureComponent } from 'react';
import ReactDom from 'react-dom';
import { Route, Switch } from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';

import Payment from './components/Payment';
import PDF from './components/Pdf'
import './index.css'

const menuRoutes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Payment,
  },
  {
    path: '/pdf',
    exact: true,
    name: 'PDF',
    component: PDF,
    key: 'PDF',
  },
  {
    path: '/:token',
    exact: true,
    name: 'Payment',
    component: Payment,
    key: 'Payment'
  }
];


function App() {
  const redirect = window.location.pathname === '/' && '/unauthorized';
  return (
    <BrowserRouter>
            <Switch>
        {redirect ? <Redirect to={redirect} /> : null}
        {menuRoutes.map((route, index) => (
          <Route
            key={route.key ? route.key : index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            />
          ))}
          <Redirect to="/unauthorized" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
