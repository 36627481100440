import React from "react";
import dayjs from "dayjs";
import { conformToMask } from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import cx from "classnames";

import { useTableStyles } from "./table-style";

import "../index.css";

const moneyMask = createNumberMask({
  prefix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
});

const getMaskedValue = (value) => {
  return conformToMask(value, moneyMask(value)).conformedValue;
};

const getValue = (value = "") =>
  `${getMaskedValue(value.slice(0, value.length - 2))},${value.slice(value.length - 2)}`;

const paymentMethods = {
  credit: "Cartão de crédito",
};

const pad = (str, max = 2) => {
  str = str.toString();
  return str.length < max ? pad("0" + str, max) : str;
};

const getDate = (date) => dayjs(date).format("DD/MM/YYYY");

const Product = ({ installments = [] }) => {
  const classes = useTableStyles();
  return (
    <div>
      <h3 className={classes.title}>Pagamento da inscrição</h3>

      <div className={classes.table}>
        <div className={classes.header}>
          <h3 className={classes.header_text} style={{ width: "10%" }}>
            Parcela
          </h3>
          <h3 className={cx(classes.header_text, classes.header_border)} style={{ width: "40%" }}>
            Responsável
          </h3>
          <h3 className={classes.header_text} style={{ width: "20%" }}>
            Data de Vencimento
          </h3>
          <h3 className={cx(classes.header_text, classes.header_border)} style={{ width: "15%" }}>
            Tipo
          </h3>
          <h3 className={classes.header_text} style={{ width: "15%" }}>
            Valor
          </h3>
        </div>

        {installments.map((installment, index) => (
          <div
            key={`${installment.ibc_id}_${index}`}
            className={cx(classes.row, {
              [classes.row_inverted]: index % 2,
            })}
          >
            <div
              className={cx(classes.row_text, classes.row_border_bottom)}
              style={{ width: "10%" }}
            >
              {pad(`${index + 1}`)}
            </div>
            <div
              className={cx(classes.row_text, classes.row_border_bottom, classes.row_border)}
              style={{ width: "40%" }}
            >
              {installment.name}
            </div>
            <div
              className={cx(classes.row_text, classes.row_border_bottom)}
              style={{ width: "20%" }}
            >
              {getDate(installment.date)}
            </div>
            <div
              className={cx(classes.row_text, classes.row_border_bottom, classes.row_border)}
              style={{ width: "15%" }}
            >
              {paymentMethods[installment.payment_method]}
            </div>
            <div
              className={cx(classes.row_text, classes.row_border_bottom)}
              style={{ width: "15%" }}
            >{`R$ ${getValue(installment.price)}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
