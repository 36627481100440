import { makeStyles } from "@mui/styles";

export const useTableStyles = makeStyles({
  title: {
    textTransform: "uppercase",
    fontSize: "16px !important",
    fontWeight: "bold",
    color: "#000",
  },
  table: {
    border: "1px solid #ccc",
    borderBottom: 0,
    borderTop: 0,
    marginBottom: 20,
  },
  header: {
    display: "flex",
    background: "#000",
  },
  header_text: {
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff",
    padding: "10px 0",
    margin: 0,
  },
  header_border: {
    borderLeft: "1px solid #fff",
    borderRight: "1px solid #fff",
  },
  row: {
    display: "flex",
    background: "#fff",
  },
  row_inverted: {
    background: "#ededed",
  },
  row_text: {
    padding: "10px 0",
    textAlign: "center",
    margin: 0,
  },
  row_border: {
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
  },
  row_border_bottom: {
    borderBottom: "1px solid #ccc",
  },
});
